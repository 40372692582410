'use strict';

let resizeHiringStepObserver;
try {
    resizeHiringStepObserver = new ResizeObserver(
        (entries) => {
            for (let entry of entries) {
                document.getElementById("hiring-step-4").classList.remove("hiring-step--4__with-geektastic--force-shape");
                resizeHiringStepObserver.unobserve(entry.target);
            }
        });
    
} catch (error) {
    console.error("ResizeObserver not supported");
    console.error(error);
}

function withGeektastic() {
    document.getElementById("hiring-step-4").classList.add("hiring-step--4__with-geektastic");
    document.getElementById("without-geektastic").classList.remove("hiring-steps__control__label--selected");
    document.getElementById("with-geektastic").classList.add("hiring-steps__control__label--selected");

    const shapeBeforeHeight = document.getElementById("hiring-step-4-group").getBoundingClientRect().height;

    document.getElementById("hiring-step-open").beginElement();
    setTimeout(() => {
        if (document.getElementById("hiring-step-4-group").getBoundingClientRect().height == shapeBeforeHeight) {
            document.getElementById("hiring-step-4").classList.add("hiring-step--4__with-geektastic--force-shape");
            resizeHiringStepObserver.observe(document.getElementById("hiring-step-4-group"));
        }
    }, 600);

}

function withoutGeektastic() {
    const hiringStep = document.getElementById("hiring-step-4");
    hiringStep.classList.remove("hiring-step--4__with-geektastic");
    hiringStep.classList.remove("hiring-step--4__with-geektastic--force-shape");

    document.getElementById("without-geektastic").classList.add("hiring-steps__control__label--selected");
    document.getElementById("with-geektastic").classList.remove("hiring-steps__control__label--selected");

    document.getElementById("hiring-step-close").beginElement();
}

document.getElementById("toggle-geektastic-hring-step")
    .addEventListener("change",
        (e) => {
            if (e.currentTarget.checked) {
                withGeektastic();
            } else {
                withoutGeektastic();
            }
        }
    );

document.getElementById("without-geektastic")
    .addEventListener("click",
        (e) => {
            let checkbox = document.getElementById("toggle-geektastic-hring-step");
            if (checkbox.checked == true) {
                document.getElementById("toggle-geektastic-hring-step").checked = false;
                withoutGeektastic();
            }
        }
    );

document.getElementById("with-geektastic")
    .addEventListener("click",
        (e) => {
            let checkbox = document.getElementById("toggle-geektastic-hring-step");
            if (checkbox.checked == false) {
                document.getElementById("toggle-geektastic-hring-step").checked = true;
                withGeektastic();
            }
        }
    );